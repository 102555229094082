<template>
    <el-dialog title="默认值设置" :close-on-click-modal="false" :visible.sync="visible" width="520px">
        <el-form ref="dataForm" :model="dataForm" inline label-width="140px" label-position="left" class="border-form">
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="Shift No 前缀" class="item">
                        <el-input class="selItemInput" type="input" v-model="dataForm.logNo" placeholder="请填写"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <!-- <el-form-item label="工程名称">
                    <el-input class="selItemInput" type="input" v-model="dataForm.projectName"
                        placeholder="请填写"></el-input>
                </el-form-item> -->
                    <el-form-item label="工程地点" class="item">
                        <el-input class="selItemInput" type="input" v-model="dataForm.projectPlace"
                            placeholder="请填写"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="监理组长" class="item">
                        <el-input class="selItemInput" type="input" v-model="dataForm.leader" placeholder="请填写"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="监理工程师" class="item">
                        <el-input class="selItemInput" type="input" v-model="dataForm.engineer"
                            placeholder="请填写"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="formBtnGroup">
                <!-- <el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button> -->
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
    name: "workloginfo-preset",
    data() {
        return {
            visible: true,
            dataForm: {
                workNo: $common.getItem("workNo"),
                recNo: '',
                logNo: '',
                projectPlace: "",
                leader: '',
                engineer: ''
            },
        }
    },
    methods: {
        init() {
            this.visible = true
            this.getPresetInfo()
        },
        getPresetInfo() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/worklogpreset/presetInfo/" + $common.getItem("workNo"),
                method: "get"
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm.workNo = $common.getItem("workNo"),
                        this.dataForm.recNo = data.body.recNo
                    this.dataForm.logNo = data.body.logNo
                    this.dataForm.projectPlace = data.body.projectPlace
                    this.dataForm.leader = data.body.leader
                    this.dataForm.engineer = data.body.engineer
                }
            });
        },
        dataFormSubmit() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/worklogpreset/save",
                method: "post",
                data: this.$http.adornData(this.dataForm)
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: 1500,
                        onClose: () => {
                            this.visible = false;
                        }
                    });
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
    width: 718px !important;
}

.width391 {
    width: 391px !important;
}

.addImgBox {
    width: 136px;
    height: 136px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}


.fondstyle {
    color: #3f649f;
    font-size: 15px;
}

::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    ;
    font-weight: bold !important;
    ;
    background-color: #E1F1FF;
    text-align: right !important;
}

.item::v-deep .el-form-item__label {
  color: #00428E !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  background-color: #E1F1FF;
  white-space: nowrap !important;
  border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
  border-bottom: solid #dadada 1px;
  border-left: solid #dadada 1px;
  border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
  border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
  display: flex !important;
  align-items: stretch !important;
  margin-bottom: 0px !important;
}
</style>
